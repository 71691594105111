<template>
  <Layout>
    <PageHeader :items="items" :title="title" />
    <div class="alertMsg">
      <b-alert v-model="showDismissibleAlert" dismissible variant="success">
        Restaurant Branches Added Successfully!
      </b-alert>
    </div>
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <h6>Details:</h6>

          <router-link
            v-if="editRight == 1"
            :to="{
              name: 'foodCourtForm',
              params: { id: this.foodCourtID, type: 'edit' }
            }"
          >
            <button
              class="btn btn-themeYellow"
              style="position: absolute;right: 10px;"
            >
              Edit <i class="uil-pen"></i>
            </button>
          </router-link>
          <div class="table-responsive col-md-8">
            <table class="table table-striped mb-0" style="margin-top:15px;">
              <tbody>
                <tr>
                  <td>FoodCourt Name</td>
                  <td>{{ allDetailsArr.title }}</td>
                </tr>
                <tr>
                  <td>Location</td>
                  <td>{{ allDetailsArr.location }}</td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td>{{ allDetailsArr.address }}</td>
                </tr>
                <tr>
                  <td>CloudKitch Representative</td>
                  <td>{{ allDetailsArr.representative }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <h6>
            Sections:
            <span style="margin-left: 20px;"
              ><button
                class="btn btn-themeYellow"
                @click="sectionModal = !sectionModal"
              >
                <i class="uil-plus"></i> Add Section
              </button>
            </span>
          </h6>

          <ul id="example-1">
            <li v-for="item in sectionArr" :key="item.foodCourtSectionID">
              <h6>{{ item.sectionName }}</h6>
              <div class="mb-3 col-md-6 row">
                <div class="col-md-5">
                  <span class="form-label" for="formrow-firstname-input"
                    >Select Restaurant Branches *</span
                  ><br />
                  <button
                    class="btn btn-themeOrange  w-md waves-effect waves-light "
                    type="button"
                    @click="openModal(item.foodCourtSectionID)"
                  >
                    Restaurant Branches
                  </button>
                </div>

                <p class="col-md-5">
                  Selected branches:<br />
                  {{ item.branchCount }}
                </p>

                <div class="col-lg-1 align-self-center d-grid mt-3">
                  <input
                    class="btn btn-themeBrown btn-block inner"
                    type="button"
                    value="Delete"
                    @click="
                      deleteSection(item.foodCourtSectionID, item.foodCourtID)
                    "
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- my list -->
      <div class="card">
        <div class="card-body">
          <h6>
            View Food Court Restaurant:
            <span style="margin-left: 20px;"> </span>
          </h6>
          <div class="card-body">
            <div class="row mt-4">
              <!-- <div class="col-sm-12 col-md-4" style="margin-bottom:15px;">
                    <div role="group" class="btn-group">
                      <button type="button" class="btn btn-themeBrown">Excel</button>
                       <button type="button" class="btn btn-themeBrown">Column Visibility</button>
                    </div>
               </div> -->
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                      style="margin-left:5px; margin-right:5px"
                    ></b-form-select
                    >&nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      class="form-control form-control-sm ms-2"
                      placeholder="Search..."
                      type="search"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :current-page="currentPage"
                :fields="restFields"
                :filter="filter"
                :filter-included-fields="filterOn"
                :items="resttableData"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                bordered
                hover
                outlined
                responsive="sm"
                striped
                @filtered="onFiltered"
              >
                <template v-slot:cell(is_operational)="data">
                  <div
                    class="form-check form-switch form-switch-md mb-2"
                    dir="ltr"
                  >
                    <input
                      v-model="data.item.is_operational"
                      class="form-check-input"
                      type="checkbox"
                      @change="
                        changeOeprationalStatus(data.item.foodCourtRestID)
                      "
                    />
                  </div>
                </template>
                <template v-slot:cell(Action)="data">
                  <router-link
                    :to="{
                      name: 'principleForm',
                      params: { id: data.item.principleID, type: 'edit' }
                    }"
                  >
                    <i
                      class="uil uil-edit-alt"
                      style="font-size: 19px;"
                      title="Edit"
                    ></i
                  ></router-link>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="rows"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- my list -->
    </div>

    <!-- modal for section add  -->
    <b-modal
      v-model="sectionModal"
      title="Add Section"
      @ok="addFoodCourtSection()"
    >
      <div class="col-md-12 mb-2">
        <label class="form-label" for="formrow-bankName-input"
          >Section Name*</label
        >
        <input
          id="formrow-bankName-input"
          v-model="sectionName"
          class="form-control"
          type="text"
        />
      </div>
    </b-modal>
    <!-- end of section add  -->
    <b-modal
      id="modalBranches"
      ok-title="Save"
      scrollable
      size="lg"
      title-class="font-18"
      @ok="addRestaurantsToFoodCourt()"
    >
      <template #modal-header>
        <h5>
          Restuarant Branches <span style="font-size: 13px;">(Within 2km)</span>
        </h5>
      </template>
      <div class="table-responsive mb-0">
        <!-- <p>
                    <b-button size="sm" class="me-2" @click="selectAllRows">Select all</b-button>
                    <b-button size="sm" @click="clearSelected">Clear selected</b-button>

                    </p> -->
        <!-- Search -->
        <div class="col-sm-12 col-md-4 row">
          <div id="tickets-table_filter" class="dataTables_filter text-md-end">
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input
                v-model="filter"
                class="form-control form-control-sm ms-2"
                placeholder="Search..."
                type="search"
              ></b-form-input>
            </label>
          </div>
        </div>
        <!-- End search -->

        <b-table
          ref="selectableTable"
          :current-page="currentPage"
          :fields="fields"
          :filter="filter"
          :filter-included-fields="filterOn"
          :items="tableData"
          :per-page="perPage"
          :select-mode="selectMode"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          bordered
          hover
          outlined
          responsive
          selectable
          striped
          @filtered="onFiltered"
          @row-selected="onRowSelected"
        >
          <template #cell(selected)="data">
            <label class="form-checkbox">
              <!-- <input type="checkbox" class="form-check-input" :value="data.item.restBranchID" :checked="data.item.rowSelected==true?true:false" >
                    <i class="form-icon"></i> -->
            </label>
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                id="checkbox-group-2"
                v-model="selectedRestBranch"
                :aria-describedby="ariaDescribedby"
              >
                <b-form-checkbox
                  :value="data.item.restBranchID"
                ></b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </template>
        </b-table>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers float-end">
            <ul class="pagination pagination-rounded mb-0">
              <!-- pagination -->
              <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="rows"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config"; // import Multiselect from "vue-multiselect";
// import Multiselect from "vue-multiselect";
//import { authHeader } from '../../../helpers/fakebackend/auth-header';
//import   helperFile   from '../../../helpers/helper';

/**
 * Advanced-form component
 */
export default {
  page: {
    title: "Foodcourt Details",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  components: {
    // Multiselect,
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "Foodcourt Details",
      items: [
        {
          text: "Foodcourt Details",
          href: "/"
        },
        {
          text: "Add",
          active: true
        }
      ],
      status: "",
      submitted: false,
      showDismissibleAlert: false,
      loading: false,
      allDetailsArr: [],
      sectionModal: false,
      sectionArr: [],
      sectionName: "",
      foodCourtSectionID: "",
      foodCourtID: "",
      // table for branch ////
      tableData: [],
      resttableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      selectMode: "multi",
      selected: [],
      selectedRestBranch: [],
      fields: [
        {
          key: "selected",
          sortable: false
        },
        {
          key: "restaurantName",
          sortable: true
        },
        {
          key: "address",
          sortable: true
        }
      ],
      restFields: [
        {
          key: "restaurantName",
          sortable: false
        },
        {
          key: "address",
          sortable: true
        },
        {
          key: "is_operational",
          label: "Operational",
          sortable: true,
          tdClass: "align-right"
        }
      ],
      editRight: "",
      deleteRight: ""
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.isEdit = this.$route.params.isEdit;
    this.isDelete = this.$route.params.isDelete;

    if (this.id) {
      sessionStorage.setItem("foodCourtID", this.id); // Save to sessionStorage
      sessionStorage.setItem("isEdit", this.isEdit); // Save to sessionStorage
      sessionStorage.setItem("isDelete", this.isDelete); // Save to sessionStorage
    }

    this.foodCourtID = sessionStorage.getItem("foodCourtID");
    this.editRight = sessionStorage.getItem("isEdit", this.isEdit);
    this.editDelete = sessionStorage.getItem("isDelete", this.isDelete);
  },
  methods: {
    getFoodCourtDetailsByID(foodCourtID) {
      this.axios
        .post(this.$loggedRole+"/getFoodCourtDetailsByID", { foodCourtID: foodCourtID })
        .then(response => {
          this.allDetailsArr = response.data.data;
          this.sectionArr = response.data.sections;
        });
    },
    getFoodRestCourtDetailsByID(foodCourtID) {
      this.axios
        .post(this.$loggedRole+"/viewFoodCourtRestaurant", { foodCourtID: foodCourtID })
        .then(response => {
          this.resttableData = response.data.data;
        });
    },
    changeOeprationalStatus(id) {
      this.axios
        .get(this.$loggedRole+"/change-food-court-operational-status/" + id)
        .then(response => {
          alert(response.data.message);
        })
        .catch(error => {
          alert(error.response.data.message);
        });
    },
    addFoodCourtSection() {
      this.axios
        .post(this.$loggedRole+"/addFoodCourtSection", {
          sectionName: this.sectionName,
          foodCourtID: sessionStorage.getItem("foodCourtID"),
          createdBy: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.sectionArr = response.data.data;
        })
        .catch(error => {
          this.loading = false;

          alert(error.response.data);
        });
    },
    deleteSection(foodCourtSectionID, foodCourtID) {
      if (
        confirm(
          "Do you really want to delete this section? You cannot undo it once deleted"
        )
      ) {
        this.axios
          .post(this.$loggedRole+"/getFoodCourtSection", {
            foodCourtID: foodCourtID,
            foodCourtSectionID: foodCourtSectionID
          })
          .then(response => {
            this.sectionArr = response.data.data;
          });
      }
    },
    addRestaurantsToFoodCourt() {
      this.axios
        .post(this.$loggedRole+"/addRestaurantsToFoodCourt", {
          sectionName: this.sectionName,
          foodCourtSectionID: this.foodCourtSectionID,
          foodCourtID: sessionStorage.getItem("foodCourtID"),
          restBranchIDs: JSON.stringify(this.selectedRestBranch),
          createdBy: this.$storageData.profile.pid,
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.sectionArr = response.data.sections;
          this.showDismissibleAlert = true;
          setTimeout(() => {
            this.showDismissibleAlert = false;
          }, 2000);
        })
        .catch(error => {
          this.loading = false;

          alert(error.response.data.data);
        });
    },

    onRowSelected(items) {
      this.selected = items;
      // console.log(this.selected);
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2);
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2);
    },

    ///// data table methods
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ////// end of datatable methods ///////////////////////////

    openModal(foodCourtSectionID) {
      this.foodCourtSectionID = foodCourtSectionID;
      // this.selectedRestBranch = [];
      this.$root.$emit("bv::show::modal", "modalBranches");
      this.readRestaurantBranchData(
        sessionStorage.getItem("foodCourtID"),
        this.foodCourtSectionID
      );
    },

    readRestaurantBranchData(foodCourtID, foodCourtSectionID) {
      this.axios
        .post(this.$loggedRole+"/getRestaurantListByFoodCourtID", {
          foodCourtID: foodCourtID,
          foodCourtSectionID: foodCourtSectionID
        })
        .then(response => {
          this.tableData = response.data.data;

          this.selectedRestBranch = [];
          for (var i = 0; i <= this.tableData.length; i++) {
            if (this.tableData[i].rowSelected == true) {
              this.selectedRestBranch.push(this.tableData[i].restBranchID);
            }
          }
        });
    }
  },
  mounted() {
    this.getFoodCourtDetailsByID(sessionStorage.getItem("foodCourtID"));
    this.getFoodRestCourtDetailsByID(sessionStorage.getItem("foodCourtID"));
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    }
  },

  middleware: "authentication"
};
</script>

<style scoped>
.profile-logo {
  height: 71px;
  width: 71px;
  background-color: #f6f6f6;
  padding: 3px;
  object-fit: contain;
}
.table-striped td {
  border: 1px solid gainsboro !important;
}
</style>
